export const homeSections = [
    {
        title: "Video Conferencing Re-imagined",
        description:
            "Enhancing interactivity, personalization, and branding possibilities",
        // backgroundImage: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703537294/web/Frame_1261153021_lnkw7f.png",
        background: "linear-gradient(5deg, black, #7a4b1c)",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707498399/Templates/Meeting2Updated_Final_2_8_dh7usz.png",
        color: 'white',
        btn: 'Go to WiZR Connect',
        toLink: "wizr-connect",
        bgColor: "#606060",
        hoverColor: "#767676"
    },
    {
        title: "Organize Your Content",
        description:
            "Crafted to give special attention to important content",
        background:
            "linear-gradient(176deg, black, #09628e)",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704298308/DOC_wizy_las_wvk7gk.png",
        // boxShadow: "0px 20px 150px 0px rgba(10, 107, 158, 0.60)",
        btn: 'Go to Library',
        bgColor: "#0C6E9F",
        toLink: "library",
        extraClass: "librarySection",
        hoverColor: "#2494cc"
    },
    {
        title: "Revolutionize the Sharing Experience",
        description:
            "Enhance meeting dynamics and follow-up, elevate sharing to its highest potential",
        backgroundImage: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703537430/web/Frame_1261153021_1_zncde5.png",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704329431/Studio_-_E2_1_2_dac9br.png",
        btn: 'Go to Studio',
        bgColor: "#ec7600",
        toLink: "studio",
        color: "#0A0A0A",
        extraClass: "studio",
        hoverColor: "#ff850c"
    },
    {
        title: "Next Level Communications",
        description:
            "PR-Agency Inspired Templates and Visuals",
        background:
            "linear-gradient(359deg, black, #623F53)",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704304596/mpublish.png",
        btn: 'Go to MPublisher',
        bgColor: "#623F53",
        color: "white",
        extraClass: "mpublisher",
        toLink: "mpublisher",
        hoverColor: "#875973"
    },
    {
        title: "Seamless Connections",
        description:
            "Fueling unparalleled collaborations",
        backgroundImage:
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703537515/web/Frame_1261153022_jv4rse.png",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710973840/Templates/r2_1_zh6rdo.png",
        btn: 'Go to WiZR X',
        bgColor: "#098D70",
        color: "#1A1A1A",
        extraClass: "wizrx",
        toLink: "wizr-x",
        hoverColor: "#0aac88"
    },
    // {
    //     title: "Supercharge with WiZR Articles",
    //     description:
    //         "Join Our Exclusive Community for Exclusive Video Insights, Real-Life Examples, and More!",
    //     backgroundImage:
    //         "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703545438/web/Group_1261153002_hafmov.png",
    //     image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703783933/web/Group_40596_2_ochpzu.png",
    //     // btn: 'Explore',
    //     // bgColor: "#333333",
    //     color: "white",
    //     extraClass: "right"
    // },
];


export const aboutSections = [
    {
        title: "Get To Know Us",
        description:
            "We are passionate about creating the next level of how we work and connect.",
            backgroundImage: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710805535/Templates/Frame_1261153093_1_2_mdpxud.jpg",
            image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710884330/Frame_1261153114_x7afeu.png",
        color: 'white',
        extraClass: "banner",
    },
    {
        title: "What unites us",
        description:
            "In MASTER WiZR we get to work on the tools that will help us work. Instead of going to other solutions for chat, video, sharing, saving, presenting and creating, we develop and polish our own tools. For our staff it brings a unique way to engage and develop. <br><br> ",
        backgroundImage:
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703684925/web/Frame_1261153014_1_h0zzjz.png",
        // image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703635334/web/Group_1261153008_vqjwbf.png",
        color: '#000000',
        extraClass: "textSection",
        boldText: "We use what we create, we dont only sell it.",
    },
    {
        title: "How We Meet",
        description:
            "We want to create the coolest video experiences - master of remote work",
        backgroundImage: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710884897/Frame_1261153026_ozngpw.jpg",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703634108/Meeting2Updated_Final_2_1_ws8poa.png",
        color: 'white',
        btn: 'Go to WiZR Connect',
        bgColor: "#ec7600",
        toLink: "wizr-connect",
        hoverColor: "#ff850c"
    },
    {
        title: "How We Connect",
        description:
            "We get to see how sharing news and info can be an experience.",
        backgroundImage:
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710884893/Frame_1261153034_mlcfd7.jpg",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703634306/StreamingView_1_1_icbjmh.png",
        btn: 'Go to WiZR X',
        bgColor: "#098D70",
        toLink: "wizr-x",
        color: "white",
        hoverColor: "#0aac88"
    },
    {
        title: "Meet the Team",
        description:
            "Introducing some of our team members",
        background: "white",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703635873/web/Frame_1261153023_ag1sl6.png",
        btn: 'Join us',
        bgColor: "#333333",
        color: "#232323",
        extraClass: "team",
        toLink: "hiring",
        hoverColor: "#767676"
    },
    {
        title: "How We Create",
        description:
            "We create through our own MPublisher for designs, branding and social media ",
        backgroundImage: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703716051/web/Frame_1261153024_ndsi94.png",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704303669/Frame_42008_any5tc.png",
        btn: 'Go to MPublisher',
        bgColor: "#623F53",
        toLink: "mpublisher",
        color: "#0A0A0A",
        extraClass: "aboutPublisher",
        hoverColor: "#875973"
    },
    {
        title: "How We Organize",
        description:
            "Using our Library and other tools we make organizing and sharing more fun",
        backgroundImage:
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703893676/web/Frame_1261153033_zpg9md.png",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703634646/Library_-_Studio_-_1_1_xbtwlg.png",
        color: "white",
        extraClass: "library",
        btn: 'Go to Library',
        bgColor: "#0C6E9F",
        toLink: "library",
        hoverColor: "#2494cc"
        // boxShadow: "0px 20px 150px 0px #7A4E68",
    },
    {
        title: "Communications <br> <b>Re-Imagined </b>",
        desc: "The future of work is rapidly evolving, and at MASTER WiZR, we lead the way in this transformation.",
        description:
            "Our innovative tools empower individuals and organizations to achieve their best work through seamless integration, creativity, and collaboration. By eliminating the limitations of traditional work software, we unlock the full potential of the modern workforce.",
        backgroundImage:
            "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703545438/web/Group_1261153002_hafmov.png",
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710884107/WhatsApp_Image_2024-02-10_at_20.46_1_lnikmf.jpg",
        color: "white",
        extraClass: "joneSection"
    },
];


export const homeSlider = [
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788953/Templates/Rectangle_15412_a13jfg.png"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710886036/Screenshot_2024-03-19_at_23.06.59_ob4j5k.png"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710868816/Templates/Rectangle_15407_khn7rr.png"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788954/Templates/Rectangle_15413_j71fsr.png"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788953/Templates/Rectangle_15411_wcfqhx.png"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710868817/Templates/Rectangle_15405_1_fhu4q9.png"
    },
        {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710788953/Templates/Rectangle_15406_nrj9gf.png"
    },

]



export const showrooms = [
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710087980/Templates/Untitled_design_e86vyw.png",
        title: "Inputs to our ",
        description: "Based on ideas, questions and interesting conversations we have gathered material to guide shared exploration.",
        subtitle: "next talks",
        right: true,
        oneline: "To inspire dialogue ",
        extraClass: "banner"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084641/Templates/Rectangle_15400_1_qqpf3y.png",
        title: "Welcome!",
        description: "Same presentations as on the web, easy to share. Also contain some additional pages. Explore which product makes the most sense.",
        toLink: "https://studio.masterwizr.com/share/shared-65edbdad7a570baad460a358-public"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710106766/Rectangle_15399_1_oof3tu.png",
        title: "License options",
        description: "Light option: Enjoy 2/3 of the features for the 1/3 of the price. Or go for the best and most comprehensive option!",
        right: true,
        toLink: "https://studio.masterwizr.com/share/shared-65edbfb57a570baad460a55b-public"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084642/Templates/Rectangle_15401_1_yrbzvc.png",
        title: "Visual Comparisons",
        description: "See the difference yourself, explore well-know companies and how they compare with our products, we simply aim to delight.",
        toLink: "https://studio.masterwizr.com/share/shared-65edbe017a570baad460a386-public"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710022549/Templates/Rectangle_15396_ydvxza.png",
        title: "Product Videos",
        description: "Enjoy our 1-2 minutes intros to the key uses and functionality for sharing, presenting, creating and more. A great way to see what you can do.",
        right: true,
        toLink: "https://studio.masterwizr.com/share/shared-658ef3bdc098bb32c2a139b3-public"
    },
    {
        image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710022589/Templates/Rectangle_15398_tbxbsu.png",
        title: "Product Presentations",
        description: "Explore which product makes the most sense in your own pace. We will help you find a few uses to get started with.",
        toLink: "https://studio.masterwizr.com/share/shared-651b17d6880e12faa02421ab-public"
    },
]